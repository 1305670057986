export default {
  getStates (state) {
    const states = []
    for (const k in state.states) {
      states.push({
        value: state.states[k].id,
        label: state.states[k].initials,
        initials: state.states[k].initials
      })
    }
    return states
  }
}
