export default {
  forSelect (state) {
    const cacs = []
    for (const k in state.cacs) {
      cacs.push({
        value: state.cacs[k].id,
        label: state.cacs[k].trading_name,
        data: state.cacs[k]
      })
    }
    return cacs
  },
  get (state) {
    const cac = JSON.parse(JSON.stringify(state.cac))
    cac.address = cac.addresses.length && cac.addresses[0]
    cac.address.country = 1 // Brasil

    const sortPhonesBy = ['mobile', 'phone', 'other']
    //AJUSTANDO OS TELEFONES VAZIOS
    if (cac.phones) {
      if (!cac.phones.filter(phone => phone.type === 'mobile').length) {
        cac.phones.unshift({id: null, phone: null, type: 'mobile' })
      }
      if (!cac.phones.filter(phone => phone.type === 'phone').length) {
        cac.phones.push({id: null, phone: null, type: 'phone' })
      }
      if (!cac.phones.filter(phone => phone.type === 'other').length) {
        cac.phones.push({id: null, phone: null, type: 'other' })
      }
      //ORDENANDO OS TELEFONES NA SEQUÊNCIA ABAIXO
      cac.phones.sort((a, b) => sortPhonesBy.indexOf(a.type) - sortPhonesBy.indexOf(b.type))
    }

    if (cac.emails && cac.emails.length) cac.email = cac.emails[0].email
    else cac.email = null
    cac.doctors = cac.doctors && cac.doctors.map(a => a.id)

    return cac
  }
}
