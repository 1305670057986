import state from './moduleCacState.js'
import mutations from './moduleCacMutations.js'
import actions from './moduleCacActions.js'
import getters from './moduleCacGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

