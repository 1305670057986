export default {
  cac: {
    address: {}
  },
  cacs: [],
  cacsGrid: {
    data: [],
    total: 0
  }
}
