import Vue from 'vue'

export default {
  SET (state, payload) {
    state.cac = Object.assign({}, state.cac, payload) //precisa ser assim para manter a reatividade
  },
  SET_CACS (state, payload) {
    state.cacs = payload
  },
  SET_CACS_GRID (state, payload) {
    state.cacsGrid = payload
  },
  STORE (state, payload) {
    state.cacs.push(payload) //precisa ser assim para manter a reatividade
    state.cacs.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome

    state.cacsGrid.data.push(payload)
    state.cacsGrid.data.sort((a, b) => { return a.trading_name.localeCompare(b.trading_name) }) // Ordena por nome
    state.cacsGrid.total = state.cacsGrid.total + 1
  },
  UPDATE (state, payload) {
    const idx = state.cacs.findIndex(o => o.id === payload.id)
    if (idx >= 0) Vue.set(state.cacs, idx, payload)

    const idx1 = state.cacsGrid.data.findIndex(o => o.id === payload.id)
    if (idx1 >= 0) Vue.set(state.cacsGrid.data, idx1, payload)
  },
  DELETE (state, payload) {
    const idx1 = state.cacs.findIndex(o => o.id === payload)
    if (idx1 >= 0) state.cacs.splice(idx1, 1) //precisa ser assim para manter a reatividade

    const idx = state.cacsGrid.data.findIndex(o => o.id === payload)
    if (idx >= 0) {
      state.cacsGrid.data.splice(idx, 1) //precisa ser assim para manter a reatividade
      state.cacsGrid.total = state.cacsGrid.total - 1
    }
  }
}
