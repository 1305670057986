import axios from '@/axios.js'

export default {
  async fetchStates ({ state, commit }) {
    if (!state.states.length) {
      try {
        const states = (await axios.get(`${process.env.VUE_APP_API_URL}/states/1`)).data
        commit('SET_STATES', { states })
      } catch (error) {
        return error
      }
    }
  }
}
